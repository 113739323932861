import { gsap } from 'gsap'

import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Layout from '@components/Layout/Layout'
import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './thank-you.module.scss'

const ThankYouPage = () => {
	const imageRef = React.useRef<HTMLDivElement>(null)

	React.useLayoutEffect(() => {
		gsap.from(imageRef.current, {
			yPercent: 50,
			opacity: 0,
			duration: 1,
		})
	}, [imageRef.current])

	return (
		<Layout className={styles.layout} containerClassName={styles.layoutContainer} type="full">
			<div ref={imageRef}>
				<StaticImage src="../images/astro/hurray.png" alt="" loading="eager" />
			</div>
			<div className={styles.page}>
				<h1 className={styles.title}>Success!</h1>
				<Wysiwyg className={styles.subtext}>
					<p>Thank you. Your application has been submitted.</p>
				</Wysiwyg>
				<Button uri="/" label="Return to homepage" large />
			</div>
		</Layout>
	)
}

export default ThankYouPage
